






































































import { Component, Vue } from "vue-property-decorator";
import {
    apiPaymentMethodGet,
    apiPaymentMethodSet,
} from "@/api/setting/payment";
import { PaymentMethodSet_Req } from "@/api/setting/payment.d";
@Component({
    components: {},
})
export default class PayMethodEdit extends Vue {
    /** S Data **/

    // 支付方式的数据
    paymentMethodData: any = [];

    // 支付方式的默认支付单选
    currentDefault: any = [];

    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getPaymentMethodList() {
        apiPaymentMethodGet()
            .then((res: any) => {
                this.currentDefault = this.basePaymentMethodList(res);
                this.paymentMethodData = res;
            })
            .catch(() => {
                this.$message.error("数据初始化失败，请刷新重载！");
            });
    }

    // 初始化支付方式的默认支付单选
    basePaymentMethodList(data: any): Array<any> {
        let array: Array<any> = [];
        Object.keys(data).map((res, i) => {
            data[res].forEach((el: any, index: any): any => {
                if (el.is_default == 1) {
                    array[i + 1] = index + 1;
                }
            });
        });
        return array;
    }

    changeStatusPaymentSet(cIndex: number, index: number) {
        this.paymentMethodData[index][cIndex].status = this.paymentMethodData[index][cIndex].status
    }

    changeRadioPaymentSet(cIndex: number, index: number) {
        this.paymentMethodData[index].forEach((item: any, i: number) => {
            this.paymentMethodData[index][i].is_default = 0
        })
        this.paymentMethodData[index][cIndex].is_default = 1
    }

    // 设置支付方式
    onSubmit(params: PaymentMethodSet_Req) {
        apiPaymentMethodSet(params)
            .then(() => {
                // this.$message.success('状态修改成功')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getPaymentMethodList();
    }
    /** E Life Cycle **/
}
